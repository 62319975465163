

.spin-display{
  padding-top: 20px;  
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: #008ff5;

}


.spin-display > p {
    font-size: 1.7rem;
    color: beige;
}
.sun-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) { */
  .sun-logo {
    animation: sun-logo-spin infinite 10s linear;
  }
/* } */



@keyframes sun-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
